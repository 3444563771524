<template>
  <div>
    <v-card flat class="mt-4 px-8">
      <v-row class="py-3" v-if="180 - $moment().diff($moment(detail.usedAt), 'days') > 0">
        <v-col cols="2" class="pt-2 mr-4">
          <!--            <v-progress-linear :value="($moment().diff($moment(detail.usedAt || new Date()), 'days') * 100)/detail.periodFirstCopy" height="8" color="#4770BD" rounded>-->
          <v-progress-linear
            :value="($moment().diff($moment(detail.usedAt || new Date()), 'days') * 100) / 180"
            height="8"
            color="#4770BD"
            rounded>
          </v-progress-linear>
        </v-col>
        <v-col cols="4">
          <!--            <span class="caption" style="color:#4770BD">캠페인 완료까지 {{detail.periodFirstCopy - $moment().diff($moment(detail.usedAt || new Date()), 'days')}}일 남았습니다.</span>-->
          <span class="caption" style="color: #4770bd"
            >캠페인 완료까지 {{ 180 - $moment().diff($moment(detail.usedAt || new Date()), 'days') }}일
            남았습니다.</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" style="font-size: 13px; color: #808080">
          {{ $moment.tz('Asia/Seoul').format('YYYY. MM. DD') }} 기준
        </v-col>
      </v-row>
      <v-row class="py-3" v-else>
        <v-col cols="6" class="pt-2 mr-4 text-center" style="color: #c2c2c2"> 캠페인이 완료되었습니다. </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="py-5">
        <v-col cols="2">
          <v-avatar :size="$vuetify.breakpoint.mdAndUp ? '128' : '64'">
            <img v-if="(detail.Influencer || {}).photo" :src="(detail.Influencer || {}).photo" />
            <img v-else-if="(detail.Youtuber || {}).thumbnail" :src="(detail.Youtuber || {}).thumbnail" />
            <img v-else src="@/assets/img/matching-placeholder.jpg" />
          </v-avatar>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '2' : '5'" :class="$vuetify.breakpoint.mdAndUp ? 'pt-5' : 'pt-2'">
          <v-row>
            <v-col cols="2">
              <naverIcon v-if="detail.taggableType === 'naver'" />
              <instaIcon v-if="detail.taggableType === 'insta'" />
              <youtubeIcon v-if="detail.taggableType === 'youtube'" />
            </v-col>
            <v-col v-if="detail.Influencer" class="subheading">{{ (detail.Influencer || {}).account }}</v-col>
            <v-col v-else-if="detail.Youtuber" class="subheading">{{ (detail.Youtuber || {}).title }}</v-col>
          </v-row>
          <v-row>
            <v-chip
              x-small
              outlined
              color="#34CD89"
              class="ma-0 mr-1 mt-2"
              v-for="(category, idx) in _.take(_.sortBy(_.toPairs(mainCategories), 1).reverse(), 2)"
              :key="idx"
              >{{ category[0] + ' ' + Math.round(category[1]) + '%' }}</v-chip
            >
          </v-row>
          <v-row>
            <v-list two-line class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-content class="pt-1">
                  <v-list-item-title style="font-size: 10px; color: #808080">일 평균 방문자</v-list-item-title>
                  <v-list-item-subtitle v-if="detail.Influencer" class="caption">{{
                    (detail.Influencer || {}).visitor | numFormat
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else-if="detail.Youtuber" class="caption">{{
                    (detail.Youtuber || {}).viewCount | numFormat
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pt-1">
                  <v-list-item-title style="font-size: 10px; color: #808080">국내 순위</v-list-item-title>
                  <v-list-item-subtitle v-if="detail.Youtuber" class="caption">{{
                    (detail.Youtuber || {}).rank
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="(detail.Influencer || {}).rank < 100000000" class="caption">{{
                    (detail.Influencer || {}).rank | numFormat
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-else class="caption">{{
                    (detail.Influencer || {}).rank
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row class="subheading" style="color: #3a3a3a"> 등록 후기 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="height: 140px">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? '3' : '7'">
              <v-img
                v-if="(detail.Influencer || {}).metadata"
                width="240"
                height="140"
                :src="
                  (detail.Influencer || {}).metadata.image
                    ? (detail.Influencer || {}).metadata.image
                    : 'https://via.placeholder.com/320x160.jpg?text=Supermebers'
                "></v-img>
            </v-col>
            <v-vol class="px-4 my-auto">
              <v-row
                v-if="(detail.Influencer || {}).metadata"
                class="justify-space-between"
                justify="center"
                align="center">
                {{ (detail.Influencer || {}).metadata.title }}
              </v-row>
              <v-row class="ma-2">
                <a :href="detail.review" target="_blank"><v-icon>open_in_new</v-icon></a>
              </v-row>
            </v-vol>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading mb-1" style="color: #3a3a3a"> 총 조회수 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="font-size: 32px; color: #4463bb">
            <v-col class="text-center">
              {{
                ((detail['viewHistories'] || []).length > 0 ? _.sumBy(detail['viewHistories'], 'views') : 0) | numFormat
              }}
            </v-col>
          </v-row>
          <v-row style="font-size: 13px; color: #808080">
            <v-col class="text-center"> 게시물 총 조회수 </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-2">
          <v-row class="subheading" style="color: #3a3a3a"> 일간 조회수 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-6">
            <naverChart
              :width="$vuetify.breakpoint.mdAndUp ? 600 : 400"
              :height="200"
              :data="viewChartData"></naverChart>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-5">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading mb-1" style="color: #3a3a3a"> 총 클릭 수 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="font-size: 32px; color: #4463bb">
            <v-col class="text-center" v-if="detail">
              {{ ((detail['viewHistories'] || []).length > 0 ? shortUrlViews : 0) | numFormat }}
            </v-col>
          </v-row>
          <v-row style="font-size: 13px; color: #808080">
            <v-col class="text-center"> 게시물 내 구매링크 클릭 수 </v-col>
          </v-row>
        </v-col>
        <v-col class="ml-2">
          <v-row class="subheading" style="color: #3a3a3a"> 구매링크 클릭 수 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row class="pl-6">
            <naverChart
              :width="$vuetify.breakpoint.mdAndUp ? 600 : 400"
              :height="200"
              :data="naverLinkChartData2"></naverChart>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '4' : '12'">
          <v-row class="subheading mb-1" style="color: #3a3a3a"> 상위노출 키워드 개수 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row style="font-size: 32px; color: #4463bb">
            <v-col class="text-center">
              {{ (detail.keywords || []).length | numFormat }}
            </v-col>
          </v-row>
          <v-row style="font-size: 13px; color: #808080">
            <v-col class="text-center"> 현재 검색결과 상위에 노출되는 키워드 수 </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? '8' : '12'" :class="$vuetify.breakpoint.mdAndUp ? 'pl-2' : ''">
          <v-row class="subheading mb-1" style="color: #3a3a3a"> 상위 노출 키워드 </v-row>
          <v-divider class="my-2"></v-divider>
          <v-row>
            <v-list style="width: 100%">
              <v-list-item>
                <v-row>
                  <v-col cols="6">
                    <v-list-item-title style="font-size: 13px; color: #757575">노출중인 키워드</v-list-item-title>
                  </v-col>
                  <!--                    <v-col cols="3">-->
                  <!--                      <v-list-item-title style="font-size: 13px;color: #757575;">키워드 검색량</v-list-item-title>-->
                  <!--                    </v-col>-->
                  <v-col cols="3">
                    <v-list-item-title style="font-size: 13px; color: #757575">노출 순위</v-list-item-title>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item-title style="font-size: 13px; color: #757575">노출 현황</v-list-item-title>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-list-item v-for="(keyword, index) in detail.keywords" :key="index">
                <v-col cols="6">
                  <v-list-item-content style="font-size: 16px; color: #484848" v-bind="attrs" v-on="on">
                    {{ keyword.campaignKeyword.name }}
                  </v-list-item-content>
                </v-col>
                <v-col cols="3">
                  <v-tooltip bottom color="white">
                    <template v-slot:activator="{ attrs, on }">
                      <v-list-item-content style="font-size: 16px; color: #484848" v-bind="attrs" v-on="on">
                        {{ keyword.rank }}위
                      </v-list-item-content>
                    </template>
                    <div
                      :style="
                        $vuetify.breakpoint.mdAndUp ? 'width: 600px; height: 200px' : 'width: 400px; height: 200px'
                      ">
                      <naverChart
                        :width="$vuetify.breakpoint.mdAndUp ? 600 : 400"
                        :height="200"
                        :data="[
                          {
                            label: '',
                            data: detail['keywordRankHistories']
                              .filter(history => history.campaignKeywordId === keyword.campaignKeywordId)
                              .map(history => {
                                return {
                                  x: history.date,
                                  y: history.rank || 0,
                                };
                              }),
                            backgroundColor: 'rgba(189, 19, 40, .7)',
                            hoverBackgroundColor: 'rgba(189, 19, 40, .7)',
                            borderColor: 'rgba(189, 19, 40, .7)',
                            borderWidth: 2,
                            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                            pointBorderColor: 'rgba(0, 0, 0, 0)',
                            pointBorderWidth: 0,
                            fill: false,
                            showLine: true,
                          },
                        ]"></naverChart>
                    </div>
                  </v-tooltip>
                </v-col>
                <v-col cols="3" v-if="keyword.rank <= 5 && keyword?.screenshot">
                  <v-btn icon @click="openScreenShotModal(keyword)">
                    <v-icon>image</v-icon>
                  </v-btn>
                </v-col>
              </v-list-item>
            </v-list>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="screenshotModal" max-width="800">
      <v-card class="pa-4">
        <v-card-title class="ml-4"
          >노출 현황
          <v-spacer></v-spacer>
          <v-btn icon @click="screenshotModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="end" align="center">
            스크린샷 저장
            <v-btn icon @click="saveScreenshot">
              <v-icon>save</v-icon>
            </v-btn>
          </v-row>
          <v-img :src="screenshot.link" width="100%" min-height="300"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import naverChart from '@/components/campaign/detail/report/NaverChart';
import { saveScreenshot } from '@/utils';

export default {
  name: 'Naverreportdetail',
  props: ['detail', 'viewChartData', 'naverLinkChartData', 'shortUrlViews'],
  components: {
    naverIcon: () => import('@/assets/img/campaign/naver_c.svg'),
    instaIcon: () => import('@/assets/img/campaign/insta_c.svg'),
    youtubeIcon: () => import('@/assets/img/campaign/youtube_c.svg'),
    naverChart,
  },
  data() {
    return {
      screenshotModal: false,
      screenshot: {
        link: '',
        name: '',
        rank: '',
        date: '',
      },
    };
  },
  methods: {
    saveScreenshot() {
      saveScreenshot(
        this.screenshot.link,
        this.screenshot.name + '_' + ((this.detail.Influencer || {}).account || ''),
        this.screenshot.rank,
        this.screenshot.date
      );
    },
    openScreenShotModal(keyword) {
      this.screenshotModal = true;
      this.screenshot = {
        link: keyword.screenshot,
        name: keyword.campaignKeyword.name,
        rank: keyword.rank,
        date: keyword.date,
      };
    },
  },
  computed: {
    naverLinkChartData2() {
      try {
        const original = this.naverLinkChartData;
        if (this._.isEmpty(original)) return [];

        const histories = original[0].data;
        const clickCount = histories.reduce((acc, cur) => {
          acc += cur.y;
          return acc;
        }, 0);
        const adjustmentValue = this.shortUrlViews - clickCount;
        if (adjustmentValue <= 0) return original;

        original[0].data[0].y = original[0].data[0].y + adjustmentValue;
        return original;
      } catch (err) {
        return [];
      }
    },
  },
};
</script>
